import React, { createContext, useState, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';

const AppContext = createContext();
const ITEM_NOT_FOUND = '-1';

export const useAppContext = () => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error(`AppContext provider is not found`);
  }

  return context;
};

export const AppProvider = ({ children }) => {
  const router = useRouter();
  const [opened, setOpened] = useState(false);
  const [isISIVisible, setIsISIVisible] = useState(false);
  const [openDropdown, setOpenDropdown] = useState([]);
  const [url, setUrl] = useState('');
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [mobileMainMenu, setMobileMainMenu] = useState(false);
  const [mobileActiveMenu, setMobileActiveMenu] = useState(0);
  const [modalISI, setModalISI] = useState(false);
  const [modalFDA, setModalFDA] = useState(false);
  const [modalSNISI, setModalSNISI] = useState(false);
  const [modalPaginator, setModalPaginator] = useState(true);

  const [openedModal, setOpenedModal] = useState(false);

  const handleModalClick = (targetUrl) => {
    setOpenedModal(!opened);
  };

  const handleClick = () => {
    setOpened(!opened);
    const isiWrapper = document.querySelector('.ISI_wrapper');

    if (!isiWrapper) {
      return;
    }

    const isiTopCord = isiWrapper.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({ left: 0, top: isiTopCord - 40, behavior: 'smooth' });
  };

  const handleOpenDropdown = (id) => {
    if (openDropdown.indexOf(id) == ITEM_NOT_FOUND) {
      setOpenDropdown((currentSection) => [...currentSection, id]);
    } else {
      setOpenDropdown(openDropdown.filter((item) => item !== id));
    }
  };

  const resetOpenDropdown = () => {
    setOpenDropdown([]);
  };

  useEffect(() => {
    router.events.on('routeChangeComplete', resetOpenDropdown);
    return () => {
      router.events.off('routeChangeComplete', resetOpenDropdown);
    };
  }, [router.events]);

  useEffect(() => {
    if (!mobileMenuOpen) {
      document.body.classList.remove('overflowHidden');
    }
  }, [mobileMenuOpen]);

  return (
    <AppContext.Provider
      value={{
        opened,
        setOpened,
        handleClick,
        isISIVisible,
        setIsISIVisible,
        openDropdown,
        setOpenDropdown,
        handleOpenDropdown,
        openedModal,
        setOpenedModal,
        handleModalClick,
        url,
        mobileMenuOpen,
        setMobileMenuOpen,
        mobileMainMenu,
        setMobileMainMenu,
        mobileActiveMenu,
        setMobileActiveMenu,
        modalISI,
        setModalISI,
        modalFDA,
        setModalFDA,
        modalSNISI,
        setModalSNISI,
        modalPaginator,
        setModalPaginator,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
