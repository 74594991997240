import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { Montserrat } from 'next/font/google';
import 'styles/variables.scss';
import 'styles/breakpoints.scss';
import 'styles/containers.scss';
import 'styles/typography.scss';
import 'styles/base.scss';
import 'styles/fonts.scss';

import { AppProvider } from 'Providers/App';
import useAB from 'Utils/hooks/useAB';

const montserrat = Montserrat({
  weight: ['300', '400', '500', '600', '700'],
  style: ['normal', 'italic'],
  subsets: ['latin'],
  fallback: ['sans-serif'],
  variable: '--font-montserrat',
});

const PAGE_SECTION_DEFAULT = 'n/a';
const PAGE_SECTION_HOME = '/';
const PAGE_SECTION_HOW_IT_WORKS = 'how-it-works';
const PAGE_SECTION_SAFETY = 'safety-and-efficacy';
const PAGE_SECTION_COST_COVERAGE = 'cost-and-coverage';
const PAGE_SECTION_FAQ = 'faq';
const PAGE_SECTION_MY_NEXPLANON = 'my-nexplanon';
const PAGE_SECTION_ES_US_HOME = 'es-us';
const PAGE_SECTION_ES_US_FAQ = 'es-us/preguntas-frecuentes';
const PAGE_SECTION_ES_US_EFECTOS = 'es-us/efectos-secundarios-y-riesgos';

const PAGE_SUB_SECTION = {
  default: PAGE_SECTION_DEFAULT,
  '/': PAGE_SECTION_HOME,
  '/what-is-nexplanon/': PAGE_SECTION_HOW_IT_WORKS,
  '/how-does-nexplanon-work/': PAGE_SECTION_HOW_IT_WORKS,
  '/birth-control-options/': PAGE_SECTION_HOW_IT_WORKS,
  '/insertion/': PAGE_SECTION_HOW_IT_WORKS,
  '/removal/': PAGE_SECTION_HOW_IT_WORKS,
  '/replacing-implant/': PAGE_SECTION_HOW_IT_WORKS,
  '/pregnancy-after-nexplanon/': PAGE_SECTION_HOW_IT_WORKS,
  '/five-fast-facts/': PAGE_SECTION_HOW_IT_WORKS,
  '/effectiveness/': PAGE_SECTION_SAFETY,
  '/side-effects/': PAGE_SECTION_SAFETY,
  '/period-changes/': PAGE_SECTION_SAFETY,
  '/calling-your-doctor/': PAGE_SECTION_SAFETY,
  '/medicine-interactions/': PAGE_SECTION_SAFETY,
  '/cost/': PAGE_SECTION_COST_COVERAGE,
  '/insurance-coverage/': PAGE_SECTION_COST_COVERAGE,
  '/questions/': PAGE_SECTION_FAQ,
  '/enrollment/': PAGE_SECTION_MY_NEXPLANON,
  '/es-us/': PAGE_SECTION_ES_US_HOME,
  '/es-us/efectos-secundarios/': PAGE_SECTION_ES_US_EFECTOS,
  '/es-us/preguntas/': PAGE_SECTION_ES_US_FAQ,
};

const pageview = (url) => {
  window.dataLayer.push({
    event: 'pageview',
    page: url,
    site_section: PAGE_SUB_SECTION[url]
      ? PAGE_SUB_SECTION[url]
      : PAGE_SUB_SECTION['default'],
  });

  window.dataLayer.push({
    event: 'gtm.load',
  });
};

const OneTrustLoadedEvent = () => {
  if (window.location.search === '') {
    window.dataLayer.push({
      event: 'OneTrustLoaded',
      OnetrustActiveGroups: window.OnetrustActiveGroups,
    });
  }
};

const EVENT_CHATBOT = {
  event: 'data-design-visible',
  'data-design-type': 'module',
  'data-design-nori_id': 'Chatbot',
  'data-design-module_name': 'Nurse Navigator',
  'data-design-module_no': '00',
};

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const ABresult = useAB();

  useEffect(() => {
    if (!window.dataLayer && ABresult) {
      return;
    }

    const eventIndex = window.dataLayer.findIndex(
      (item) =>
        item.event === EVENT_CHATBOT.event &&
        item['data-design-nori_id'] === EVENT_CHATBOT['data-design-nori_id']
    );

    const eventItem = {
      ...EVENT_CHATBOT,
      'data-design-ab_test': ABresult === 'B' ? 'Show' : 'Hide',
    };

    if (eventIndex === -1) {
      window.dataLayer.push(eventItem);
    } else {
      window.dataLayer[eventIndex] = { ...eventItem };
    }
  }, [ABresult]);

  useEffect(() => {
    if (!window.dataLayer) {
      return;
    }

    pageview(`${router.pathname}/`.replaceAll('//', '/'));
    router.events.on('routeChangeComplete', pageview);
    router.events.on('routeChangeComplete', OneTrustLoadedEvent);
    return () => {
      router.events.off('routeChangeComplete', pageview);
      router.events.off('routeChangeComplete', OneTrustLoadedEvent);
    };
  }, [router.events]);

  return (
    <div className={montserrat.variable}>
      <AppProvider>
        <Component {...pageProps} />
      </AppProvider>
    </div>
  );
}

export default MyApp;
