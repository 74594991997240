import { useState, useEffect } from 'react';

const abSettings = {
  AB_POSITION: 0,
  COOKIE_NAME: 'orgab_layout',
  DEFAULT: 'B',
  OPTIONS: ['A', 'B'],
};

export const getCookieValue = (cookieName) => {
  const cookieString = document.cookie;
  const cookies = cookieString.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(cookieName + '=')) {
      return cookie.substring(cookieName.length + 1);
    }
  }

  return '';
};

export const useAB = () => {
  const [result, setResult] = useState(null);

  useEffect(() => {
    const cookieValue = getCookieValue(abSettings.COOKIE_NAME).charAt(
      abSettings.AB_POSITION
    );
    const abValue = abSettings.OPTIONS.includes(cookieValue)
      ? cookieValue
      : abSettings.DEFAULT;

    setResult(abValue);
  }, []);

  return result;
};

export default useAB;
